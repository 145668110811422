export const setSelfieArray = (data)=>{
    console.log('DATA: ', data)
    const selfiArray = []
    if(data.Face.Selfie !== null){
        selfiArray.push(data.Face.Selfie.DocumentSection_Id)
        if(data.Face.Selfie.ComparisonVsIDEFront !== null){
            selfiArray.push(data.Face.Selfie.ComparisonVsIDEFront.DocumentSection_Id)
        }
    }

    return selfiArray
}
import axios from 'axios';
import { AppUrls, } from './Globals';
import { Config } from './Config';

export const validationRequests = {}

validationRequests.PostFileValidation = (data) => {
  return axios.post(
    AppUrls.PostFileValidation,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

validationRequests.PostDocumentValidation = (data) => {
  return axios.post(
    AppUrls.PostDocumentValidation,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

validationRequests.GetCatpcha = (data) => {
  return axios.post(
    AppUrls.GetCatpcha,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigNoAuth()
  ).then(handleResponse)
}

validationRequests.SendPinValidation = (data) => {
  return axios.post(
    AppUrls.SendPinValidation,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigNoAuth()
  ).then(handleResponse)
}

validationRequests.SendDocExternalVal = (data) => {
  return axios.post(
    AppUrls.SendDocExternalVal,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

validationRequests.GetResultadosValidaciones = (data) => {
  return axios.post(
    AppUrls.GetResultadosValidaciones,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

validationRequests.GetRegisterGeolocationFile = (data) => {
  return axios.post(
    AppUrls.GetRegisterGeolocationFile,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

validationRequests.GetCustomValidationFile = (data) => {
  return axios.post(
    AppUrls.GetCustomValidationFile,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

validationRequests.ProcessValidationRules = (data) => {
  return axios.post(
    AppUrls.ProcessValidationRules,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

let handleResponse = (response) => {
  let respObject = response.data;//BodySecurityResponse(response.data);
  if (!respObject.IsOK) {
    let responseObject = { message: respObject.Messages + ' ' + (respObject.TansactionId ? respObject.TansactionId : '') + ' ' + (respObject.statusText ? respObject.statusText : '') };
    let error = (respObject.Body || responseObject);
    return Promise.reject(error);
  }
  return respObject;
}
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { validationActions, workFlowActions } from "../../store/actions";
import { useParams } from "react-router-dom";
import CircleIcon from "@mui/icons-material/Circle";
import { Buttons } from "../Buttons";
import { Alert } from "../Alert";
import CorrectIcon from "../../assets/icons/correct.svg"
import PendingIcon from "../../assets/icons/pending.svg"
import WarningIcon from "../../assets/icons/warning.svg"
import WrongIcon from "../../assets/icons/wrong.svg"
import { UseImage } from "../UseImage";
import { withStyles } from "@mui/styles";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.secondary.light,
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

export const ResultValidations = ({ OnReturn }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [resultInfo, setResultInfo] = useState(null);
  const [continueWFAction, setContinueWFAction] = useState(false)

  const resultValidations = useSelector((state) => state.ResultadosVal);
  const wfContinueData = useSelector((state) => state.ContinueWorkFlowData);

  useEffect(() => {
    dispatch(validationActions.GetResultadosValidaciones({ File_Id: id }));
  }, []);

  useEffect(() => {
    console.log("resultValidations:::", resultValidations);
    if (resultValidations?.items) {
      setResultInfo(resultValidations?.items?.ResultadosValidaciones);
    }
  }, [resultValidations]);

  useEffect(() => {
    if (wfContinueData.items !== undefined) {
      continueWFAction && OnReturn()
    } else if (wfContinueData.error !== undefined) {
      Alert({ icon: "error", title: wfContinueData.error })
    }
  }, [wfContinueData]);

  const showValidationPerGroup = (valList) => {
    return valList?.map((val, i) => {
      return <Stack key={i + "2"} sx={{ flexDirection: 'row', backgroundColor: '#f2f2f2', borderBottom:/*  !val?.Resaltado ?  */'2px solid #c7c7c7'/* : 'none' */ }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', py: 3, pl: 2, gap: 2, width: '35% !important' }}>
          <Typography variant={val?.Resaltado ? "body1" : "body2"}>{val?.Campo ?? 'Validacion facial'}</Typography>
          {/* <Typography variant="body2">Porcentaje de validacion</Typography> */}
        </Box>
        <Box sx={{ py: 3, width: '70%' }}>
          {returnTypeVal(val?.TipoValor, val?.Valor)}
        </Box>
      </Stack>;
    });
  };

  const returnTypeVal = (type, value) => {
    switch (type) {
      case "Icono":
        const circleColor = (value === "Correcto" ? 'green' : value === "Incorrecto" ? 'red' : value === "No se pudo validar" ? "orange" : "yellow")

        return <HtmlTooltip
          title={value}
          placement='bottom' 
          arrow>
          <Box sx={{width: '1.5rem'}}><Circle color={circleColor} /></Box>
        </HtmlTooltip>
      case "Texto":
      default:
        return <Typography sx={{ textOverflow: 'ellipsis', wordBreak: 'break-word' }}>{value}</Typography>
    }
  }

  const handleContinue = () => {
    setContinueWFAction(true)
    dispatch(workFlowActions.ContinueWorkFlow({ File_Id: id }));
  }

  return (
    <Stack>
      {/* <Typography>Descripcion</Typography> */}
      <Stack sx={{ p: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              alignItems: "center",
            }}
          >
            <Circle color={"green"} />
            <Typography>Correcto</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              alignItems: "center",
            }}>
            <Circle color={'red'} />
            <Typography>Incorrecto</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              alignItems: "center",
            }}>
            <Circle color={'orange'} />
            <Typography>No se pudo validar</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              alignItems: "center",
            }}>
            <Circle color={'yellow'} />
            <Typography>Pendiente</Typography>
          </Box>
        </Box>
        {resultInfo?.map((grouper, i) => {
          return (
            <Box key={i + "1"}>
              <Stack
                sx={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "2px solid red",
                  paddingY: 2,
                }}
              >
                <Box sx={{ pl: 2 }}>
                  <Typography variant="h6">{grouper?.Agrupador}</Typography>
                </Box>
              </Stack>
              <Stack>
                {showValidationPerGroup(grouper?.Validaciones)}
              </Stack>
            </Box>
          );
        })}
      </Stack>
      <Buttons onClickConfirm={handleContinue} />
    </Stack>
  );
};

/* const Circle = forwardRef({ color }, ref) => {
  return (
    <Box sx={{ color: color ?? 'gray' }}>
      <CircleIcon fontSize="small" />
    </Box>
  );
}; */


const Circle = forwardRef(({ color }, ref) => {
  const iconSelect = {
    "green": CorrectIcon,
    "yellow": PendingIcon,
    "orange": WarningIcon,
    "red": WrongIcon
  }
  return (
    <Box ref={ref}>
      <UseImage src={iconSelect[color]} style={{ width: '1.5rem' }} />
    </Box>
  );
});
export const validationConstants = {
   VALIDATION_FILE_REQUEST: 'VALIDATION_FILE_REQUEST',
   VALIDATION_FILE_SUCCESS: 'VALIDATION_FILE_SUCCESS',
   VALIDATION_FILE_FAILURE: 'VALIDATION_FILE_FAILURE',
  
   VALIDATION_DOCUMENT_REQUEST: 'VALIDATION_DOCUMENT_REQUEST',
   VALIDATION_DOCUMENT_SUCCESS: 'VALIDATION_DOCUMENT_SUCCESS',
   VALIDATION_DOCUMENT_FAILURE: 'VALIDATION_DOCUMENT_FAILURE',

   VALIDATION_CATPCHA_REQUEST: 'VALIDATION_CATPCHA_REQUEST',
   VALIDATION_CATPCHA_SUCCESS: 'VALIDATION_CATPCHA_SUCCESS',
   VALIDATION_CATPCHA_FAILURE: 'VALIDATION_CATPCHA_FAILURE',

   VALIDATION_PIN_REQUEST: 'VALIDATION_PIN_REQUEST',
   VALIDATION_PIN_SUCCESS: 'VALIDATION_PIN_SUCCESS',
   VALIDATION_PIN_FAILURE: 'VALIDATION_PIN_FAILURE',

   DOCEXTERNAL_VAL_REQUEST: 'DOCEXTERNAL_VAL_REQUEST',
   DOCEXTERNAL_VAL_SUCCESS: 'DOCEXTERNAL_VAL_SUCCESS',
   DOCEXTERNAL_VAL_FAILURE: 'DOCEXTERNAL_VAL_FAILURE',

   RESULT_VAL_REQUEST: 'RESULT_VAL_REQUEST',
   RESULT_VAL_SUCCESS: 'RESULT_VAL_SUCCESS',
   RESULT_VAL_FAILURE: 'RESULT_VAL_FAILURE',

   REGISTER_GEOLOCATION_REQUEST: 'REGISTER_GEOLOCATION_REQUEST',
   REGISTER_GEOLOCATION_SUCCESS: 'REGISTER_GEOLOCATION_SUCCESS',
   REGISTER_GEOLOCATION_FAILURE: 'REGISTER_GEOLOCATION_FAILURE',

   CUSTOMVALIDATION_FILE_REQUEST: 'CUSTOMVALIDATION_FILE_REQUEST',
   CUSTOMVALIDATION_FILE_SUCCESS: 'CUSTOMVALIDATION_FILE_SUCCESS',
   CUSTOMVALIDATION_FILE_FAILURE: 'CUSTOMVALIDATION_FILE_FAILURE',   
   
   PROCESS_VALIDATIONRULES_REQUEST: 'PROCESS_VALIDATIONRULES_REQUEST',
   PROCESS_VALIDATIONRULES_SUCCESS: 'PROCESS_VALIDATIONRULES_SUCCESS',
   PROCESS_VALIDATIONRULES_FAILURE: 'PROCESS_VALIDATIONRULES_FAILURE',  
  }
  
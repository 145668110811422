import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { notificationActions } from '../../store/actions';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button, styled, useTheme,
  Grid, Box, Stack
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import HelpIcon from '@mui/icons-material/Help';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { withStyles } from '@mui/styles';
import CircleIcon from '@mui/icons-material/Circle';
import { CustomAlert } from "../CustomAlert";
// import { ReactComponent as IconTemp } from '../../assets/dictamen.svg'

import facialIconGray from '../../assets/icons/verificationIcons/gray/facialIcon.svg'
import nominalIconGray from '../../assets/icons/verificationIcons/gray/nominalIcon.svg'
import renapoIconGray from '../../assets/icons/verificationIcons/gray/renapoIcon.svg'

import facialIconYellow from '../../assets/icons/verificationIcons/yellow/facialIcon.svg'
import nominalIconYellow from '../../assets/icons/verificationIcons/yellow/nominalIcon.svg'
import renapoIconYellow from '../../assets/icons/verificationIcons/yellow/renapoIcon.svg'

import facialIconRed from '../../assets/icons/verificationIcons/red/facialIcon.svg'
import nominalIconRed from '../../assets/icons/verificationIcons/red/nominalIcon.svg'
import renapoIconRed from '../../assets/icons/verificationIcons/red/renapoIcon.svg'

import facialIconGreen from '../../assets/icons/verificationIcons/green/facialIcon.svg'
import nominalIconGreen from '../../assets/icons/verificationIcons/green/nominalIcon.svg'
import renapoIconGreen from '../../assets/icons/verificationIcons/green/renapoIcon.svg'


import { paths, getDateHour, routes } from '../../utils'
import { UseImage, GenericHeaderActions, CustomLoadingOverlay } from '../index';
import { ModalApproval } from '../modals';
//ICONS
import loadDoc from '../../assets/loadDoc.svg'
import profile from '../../assets/perfil.png';
import groupPhoto from '../../assets/grupo.svg'

import { useStyles } from './TabsStyle'
import { mainStyles } from '../../theme/mainStyles'

const TableCellCustom = styled(TableCell)(({ theme }) => ({
  color: `${theme.palette.secondary.dark} !important`,
  fontWeight: 'bold !important',
}));

export function GeneralInfo({ data, docs, val, actors, verifications, getInfoDispatch }) {
  const theme = useTheme()
  const localStyles = useStyles(theme)
  const cardStyle = mainStyles.cards()
  const path = window.location.pathname
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const [rows, setRows] = useState([{ name: 'Folio:', details: data.Folio }])
  const [openDeclineConfirm, setOpenDeclineConfirm] = useState(false)
  const stateAuth = useSelector((state) => state.AuthenticationReferer);
  const rdyCancelled = useSelector(state => state.UpdateCancelFilesNotifications)
  const cancelables = useSelector(state => state.GetNotificationsCancelables)

  const isAdmin = stateAuth.items?.UserLoginData?.SecurityLoginData?.IsAdmin && path.includes(paths.recordatorios)
  const noDeclineAllowed = () => {
    if (isAdmin) {
      if (cancelables.items !== undefined) {
        const dt = cancelables.items.find(item => item.Folio == data.Folio)
        const ultimatum = dt?.Metadata?.find(prop => prop.Name === 'Estatus').Value == 'Notificación Ultimátum'
        const dias = dt?.Metadata?.find(prop => prop.Name === 'Dias').Value == '0'
        if (ultimatum || dias) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    } else {
      return true
    }
  }
  //*Tooltip

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.secondary.light,
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);

  //*General Data Table*
  const { id } = useParams()
  const createData = (name, details) => {
    return { name, details };
  }

  useEffect(() => {
    if (rdyCancelled.items !== undefined && !rdyCancelled.loading) {
      navigate(routes.recordatorios)
    }
  }, [rdyCancelled])


  const cancellAll = (files) => {
    dispatch(notificationActions.UpdateCancelFiles({ "FileIds": files }))
  }

  const handleDecline = () => {
    cancellAll([data.Id])
  }

  const getSomeMetada = (array) => {
    const arrayData = array.filter((data) => data.Name === "Puesto" || data.Name === "Dependencia" || data.Name === "Tipo de proceso"
      || (data.Name === "RFC" && data.GroupName == 'Información del Asegurado'))
    arrayData.forEach(element => {
      setRows(rows => [...rows, createData(`${element.Name}`, element.Value?.toUpperCase())])
    });
  }

  useEffect(() => {
    console.log('data::', data)
    if (data.Name !== null) {
      setRows(rows => [...rows, createData('Nombre (s):', `${data.FullName?.toUpperCase()}`)])
    }
    /* if (data.Validations !== null) { */
    setRows(rows => [...rows, createData('Verificaciones:', { facial: true, renapo: false, list: false })])
    /* } */
    if (data.PhoneNumber !== null) {
      setRows(rows => [...rows, createData('Celular:', data.PhoneNumber)])
    }
    if (data.Email !== null) {
      setRows(rows => [...rows, createData('Correo electrónico:', data.Email?.toUpperCase())])
    }
    if (data.CreationDateFormatted !== null) {
      setRows(rows => [...rows, createData('Fecha de creación:', data.CreationDateFormatted)])
    }
    if (data.Metadata.length > 0) {
      getSomeMetada(data.Metadata)
    }
  }, [])

  const handleOpenDeclineConfirm = () => {
    setOpenDeclineConfirm(true)
  }

  /*   const returnIconVerification = (type) => {
      switch (type) {
        case 'Verificación facial':
          return {
            0: facialIcon,
            1: facialIcon,
            2: facialIcon,
            3: facialIcon,
          }
        case 'Verificación RENAPO':
          return {
            0: renapoIcon,
            1: renapoIcon,
            2: renapoIcon,
          }
  
        case 'Verificación a Lista Nominal':
          return {
            0: nominalIcon,
            1: nominalIcon,
            2: nominalIcon,
          }
      
        default:
          break;
      }
    } */

  const returnIconVerification = {
    'Verificación facial': {
      0: facialIconGray,
      1: facialIconGreen,
      2: facialIconYellow,
      3: facialIconRed,
    },
    'Verificación RENAPO': {
      0: renapoIconGray,
      1: renapoIconGreen,
      2: renapoIconRed,
    },
    'Verificación a Lista Nominal': {
      0: nominalIconGray,
      1: nominalIconGreen,
      2: nominalIconRed,
    }
  }

  const defaultIcon = (type) => {
    switch (type) {
      case 'Verificación facial':
        return facialIconGray
      case 'Verificación RENAPO':
        return renapoIconGray
      case 'Verificación a Lista Nominal':
        return nominalIconGray
      default:
        break;
    }
  }

  console.log('verifications::', verifications)
  return (
    <div className={docs.length > 0 ? localStyles.tabWithData : localStyles.tabWithoutDocs}>
      <Grid container display={'flex'} flexDirection={'column'}>
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'center'} width={'100%'}>
          <div className={docs.length > 0 ? localStyles.data : localStyles.dataThree}>
            <GenericHeaderActions LabelTitle="Datos generales" />
            <div className={localStyles.personalInfo}>

              <UseImage src={actors?.length > 0 ? groupPhoto : profile} className={localStyles.img} />

              <TableContainer className={localStyles.table}>
                <Table>
                  <TableBody>
                    {rows.map((row, i) => (
                      <TableRow key={i + '1'}>
                        <TableCellCustom align="left" style={{
                          borderBottom: "none",
                          paddingBottom: 10,
                        }} padding="none"
                        >
                          {row.name}
                        </TableCellCustom>
                        {row.name === 'Verificaciones:' ?
                          <TableCell align="left" style={{
                            borderBottom: "none",
                            paddingBottom: 10
                          }} padding="none" >
                            <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'start', gap: 2 }}>
                              {verifications?.map((val, i) => {

                                const icon = returnIconVerification?.[val?.Verificacion]?.[val?.IndiceEstatus] ?? defaultIcon(val?.Verificacion)
                                return (val.Estatus !== "" || val.Resultado !== "" ? <Tooltip
                                  title={
                                    <Stack
                                      flexDirection={'column'}
                                      alignItems={'center'}>
                                      <Typography sx={{ fontSize: '10px !important' }}>{val.Estatus}</Typography>
                                      <Typography sx={{ fontSize: '10px !important' }}>{val.Resultado}</Typography>
                                    </Stack>
                                  }
                                  placement='top' key={i + '1'}>
                                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '4rem' }}>
                                    <Box sx={{height: '40px' }}><UseImage src={icon} style={{ width: '2rem', aspectRatio: '1' }} /></Box>
                                    <Box sx={{height: '50px'}}><Typography sx={{ fontSize: '10px !important' }} textAlign={'center'}>{val.Verificacion}</Typography></Box>
                                  </Box>
                                </Tooltip>
                                  : <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '4rem' }}>
                                    <Box sx={{height: '40px' }}><UseImage src={icon} style={{ width: '2rem', aspectRatio: '1' }} /></Box>
                                    <Box sx={{height: '50px'}}><Typography sx={{ fontSize: '10px !important' }} textAlign={'center'}>{val.Verificacion}</Typography></Box>
                                  </Box>)
                              }
                              )}
                            </Stack>
                          </TableCell>
                          : <TableCell align="left" style={{
                            borderBottom: "none",
                            paddingBottom: 10
                          }} padding="none" >
                            {row.details}
                          </TableCell>}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {path.includes(paths.folio)
                && (val.FileApproval === null
                  && val.ShowSelectApproval === true
                  && (val.ApprovalInDocument === "0" ||
                    val.ApprovalInDocument === null ||
                    val.ApprovalInDocument == false)
                  && val.ActorVal === null)
                ?
                <ModalApproval folioId={data.Id} actorId={null} />
                : ""}
            </div>
          </div>
          {
            docs.length > 0 &&
            <div className={localStyles.dataTwo} >

              <div>
                <div className={localStyles.miniContainer}>
                  <Stack display={'flex'} flexDirection={isAdmin ? 'column-reverse' : 'row'} alignItems={'start'} justifyContent={'space-between'} sx={{ width: '100%' }}>
                    <Box sx={isAdmin ? { pt: 1 } : {}}>
                      <Typography variant="p" className={localStyles.title}>Documentación cargada
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <Typography variant="p" className={localStyles.docs}>Estatus de la documentación:</Typography>
                              <br></br>
                              <Typography variant="p" className={localStyles.docs}> <CheckCircleIcon style={{ color: "#00847F" }} />Documento aprobado</Typography>
                              <br></br>
                              <Typography variant="p" className={localStyles.docs}> <CancelIcon style={{ color: "red" }} />Documento rechazado</Typography>
                              <br></br>
                              <Typography variant="p" className={localStyles.docs}> <CircleIcon style={{ color: "#00847F" }} />Documento cargado</Typography>
                            </React.Fragment>
                          }
                          placement='bottom-start'
                        >
                          <HelpIcon />
                        </HtmlTooltip>
                      </Typography>
                    </Box>
                    <Stack display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'flex-end'} sx={{ width: isAdmin ? '100%' : '50%' }} >
                      {isAdmin ?
                        <Box display={'flex'} flexDirection={'row'} alignItems={'flex-end'} justifyContent={'end'}>
                          <Button
                            disabled={!noDeclineAllowed}
                            variant="contained"
                            sx={{ height: 45, px: 10, maxWidth: 300, mr: 1 }}
                            onClick={handleOpenDeclineConfirm} >
                            Rechazar
                          </Button>
                        </Box> : null}

                      <Button
                        variant={isAdmin ? 'outlined' : "contained"}
                        sx={{ height: 45, px: 10, maxWidth: 300, mr: 1 }}
                        startIcon={< RefreshIcon />}
                        onClick={() => getInfoDispatch()} >
                        Actualizar
                      </Button>
                    </Stack>
                  </Stack>
                </div>

                <div >
                  {docs.map((card) => {
                    if (card.grupo !== '.CER' && card.grupo !== '.KEY') {
                      return <Accordion
                        classes={{
                          root: localStyles.accordion
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ArrowDropDownIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className={localStyles.textGroup} >{card.grupo}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <TableContainer>
                            <Table>
                              <TableBody>
                                {card.documentos.map((row) => (
                                  <TableRow>
                                    <TableCell align="left" style={{
                                      borderBottom: "none",
                                      paddingBottom: 10,
                                    }} padding="none"
                                      width="4%"
                                      className={localStyles.docs}>
                                      {row.validacion === null ? <UseImage src={loadDoc} type='img' className={cardStyle.loadDocIcon} />
                                        : row.validacion ? <CheckCircleIcon style={{ color: "#00847F" }} />
                                          : row.validacion === false ? <CancelIcon style={{ color: "red" }} /> : ''}
                                    </TableCell>
                                    <TableCell align="left" style={{
                                      borderBottom: "none",
                                      paddingBottom: 10,
                                    }} padding="none"
                                      width="41%"
                                      className={localStyles.docs}>
                                      {row.nombre}
                                    </TableCell>
                                    <TableCell align="center" style={{
                                      borderBottom: "none",
                                      paddingBottom: 10,
                                    }} padding="none"
                                      width="25%"
                                      className={localStyles.information}>
                                      {row.fechaDeVal}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>

                        </AccordionDetails>
                      </Accordion>
                    } else {
                      return ''
                    }
                  })}
                </div>

              </div>
            </div>
          }
        </Box>
        {rdyCancelled.loading ?
          <div className={localStyles.spinSection}>
            <CustomLoadingOverlay
              active={rdyCancelled.loading} text="Espera un momento por favor..."
            />
          </div> : null}

        {openDeclineConfirm ?
          <CustomAlert
            containerStyle={{ padding: '20px' }}
            closeAction={() => {
              setOpenDeclineConfirm(false);
            }}
            open={openDeclineConfirm}
            title="Rechazar Solicitud"
            body={`Estas seguro de rechazar la solicitud ${data.Folio}?`}
            leftButtonText="Cancelar"
            leftButtonAction={() => {
              setOpenDeclineConfirm(false);
            }}
            rightButtonText="Aceptar"
            rightButtonAction={handleDecline}
          /> : null}
      </Grid>
    </div>
  )
}